import React, { useEffect } from 'react';
import auth from "../auth";
import Version from "../version";
import ServerState from "../controls/ServerState";
import { useConnect } from "dotnetify";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { LargeButton, SmallButton } from '../controls/components/Buttons';
import Icon from "../components/Selfcare/Common/Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactLoading from 'react-loading';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { Alert, AlertTitle } from '@mui/material';

export default function ChangeSubscriptionDataLevel(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const { vm, state, setState } = useConnect("ChangeSubscriptionDataLevel",
        {
            show: false,
            redirect: null,
            toUrl: null,
            ChangeDataLevelDialogOpen: false,
            CancelChangeDialogOpen: false,
            OrderSent: false,
            OrderError: '',
            OrderOpen: '',
            DisableButtons: false,
            buzy: false,
            Loading: false,
            EndOfMonth: false,
            SelectedPackageNo: '',
            CurrentPackageNo: '',
        },
        {
            headers: {
                Authorization: "Bearer " + auth.getAccessToken(),
                ClientVersion: Version.getVersion(),
                ServerToken: window.sessionStorage.ServerToken
            },
            vmArg: {
                SubscriptionNo: props.SubscriptionNo,
            },
            exceptionHandler: _ => auth.signOut()
        });
    useEffect(() => {
        setTimeout(
            function () {
                setState({ DisableButtons: state.OrderOpen })
            },
            10
        );
    }, [state.OrderOpen])


    const handleConfirmChangeDataLevelDialogClose = () => {
        vm.$dispatch({ PerformChange: state.SelectedPackageNo });
        setState({ ChangeDataLevelDialogOpen: false, buzy: true });
    }
    const handleLevelChange = (event) => {
        vm.$dispatch({ SelectLevel: event.target.value });
        setState({ SelectedPackageNo: event.target.value });
    }

    const handleChangeDataLevelDialogClose = () => {
        setState({ ChangeDataLevelDialogOpen: false, error: null });
    }
    const handleCancelChangeDialogClose = () => {
        setState({ CancelChangeDialogOpen: false, error: null });
    }
    const handlePerformCancel = () => {
        vm.$dispatch({ PerformCancel: true });
        setState({ CancelChangeDialogOpen: false });
    }


    return (
        <>
            <ServerState serverState={state} setState={setState} handleClose={props.handleClose} />
            <div style={{ height: '100vh' }}>
                <Dialog
                    open={state.ChangeDataLevelDialogOpen}
                    onClose={handleChangeDataLevelDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">Endre datagrense</DialogTitle>
                    <DialogContent>
                        <div>Ønsker du å bytte datagrense fra {state.CurrentLevelDescription} til {state.SelectedLevelDescription}?</div>
                        {state.EndOfMonth ? <div>Siden du allerede har brukt mer enn {state.SelectedLevelDescription}, vil endringen skje fra og med neste måned.</div> : <div>Endringen av datagrensen vil skje umiddelbart så fort endringen er bekreftet.</div>}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton variant="contained" color="primary" onClick={handleConfirmChangeDataLevelDialogClose}>
                            {t('selfcare:button.confirmuc')}
                        </LargeButton>
                        <LargeButton variant="text" color="secondary" onClick={handleChangeDataLevelDialogClose}>
                            {t('selfcare:button.canceluc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={state.CancelChangeDialogOpen}
                    onClose={handleCancelChangeDialogClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{state.IsSweden ? 'Ångra beställning' : 'Kansellere bestilling'}</DialogTitle>
                    <DialogContent>
                        {state.IsSweden ? 'Önskar du avbryta beställningen?' : 'Vil du kansellere bestillingen?'}
                    </DialogContent>
                    <DialogActions>
                        <LargeButton variant="contained" color="primary" onClick={handlePerformCancel} >
                            {t('selfcare:button.confirmuc')}
                        </LargeButton>
                        <LargeButton variant="text" color="secondary" onClick={handleCancelChangeDialogClose}>
                            {t('selfcare:button.canceluc')}
                        </LargeButton>
                    </DialogActions>
                </Dialog>
                {state.IsAdministrating && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center' }}><span dangerouslySetInnerHTML={{ __html: t('selfcare:info.now-administrating-number', { 0: state.AdministratedMsisdn }) }} /></div>}
                <div style={{ backgroundColor: theme.palette.primary.main, color: '#FFF', padding: '20px' }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} sm={10}>
                            <Icon name="money" size={48} color="#FFFFFF" />
                            <div style={{ fontSize: '20px', fontWeight: '700', maxWidth: '900px' }}>
                                Datagrense Hyttebredbånd
                            </div>
                            <p>Her kan du sette en grense på hvor mye data du kan bruke. Du betaler fortsatt bare etter hvor mye data du faktisk bruker.</p>
                            <p>De første 25 GB er inkludert i månedsprisen på 349,-. Etter det betaler du 2 kr per GB.</p>
                        </Grid>
                    </Grid>
                </div>
                <form>
                    {!state.CanChangePriceplan && <div style={{ backgroundColor: 'black', color: 'white', padding: '5px', textAlign: 'center', borderBottom: '1px grey solid' }}>For å endre datagrense må ditt mobilnummer være administrator eller administratoren for ditt kundeforhold må gi ditt mobilnummer tilgang til dette.</div>}
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={10}>
                            <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: '20px 10px' }}>
                                <RadioGroup aria-label="Velg datagrense" value={state.SelectedPackageNo} onChange={handleLevelChange} style={{ width: '100%', maxWidth: '320px' }}>
                                    {state.Levels ? state.Levels.map((level) => {
                                        return (
                                            <FormControlLabel key={level.SortNo} style={{ borderRadius: '10px', padding: '18px 18px 18px 8px', marginBottom: '20px', marginRight: '0px', marginLeft: '0px', width: '100%', maxWidth: '320px', border: level.PackageNo === state.SelectedPackageNo ? `2px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.border.main}`, background: level.PackageNo === state.SelectedPackageNo ? `${theme.palette.background.selected} 0% 0% no-repeat padding-box` : '#FFFFFF 0% 0% no-repeat padding-box' }}
                                                value={level.PackageNo}
                                                control={<Radio color="primary" />}
                                                label={<div><div style={{ fontSize: '18px', fontWeight: 700 }}>{level.Description}{level.EndOfMonth && <span style={{ fontSize: '14px', fontWeight: 500, verticalAlign: 'text-top' }}> (Endres ved månedsskifte)</span>}</div><div style={{ fontSize: '14px', fontWeight: 300 }}>{level.MaxPrice}</div></div>}
                                                labelPlacement="end"
                                                disabled={state.buzy || !state.CanChangePriceplan || state.DisableButtons}
                                            />
                                        )
                                    }) : null}
                                </RadioGroup>
                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                    <SmallButton color="primary" variant="contained" disabled={state.SelectedPackageNo === state.CurrentPackageNo || !state.CanChangePriceplan || state.buzy || state.DisableButtons} onClick={() => { setState({ ChangeDataLevelDialogOpen: true }); }} color="primary" style={{ marginRight: '10px' }}>
                                        {t('selfcare:button.confirmuc')}
                                    </SmallButton>
                                    <SmallButton variant="text" color="secondary" onClick={() => { props.handleRefresh(); props.handleClose(); }}>
                                        {t('selfcare:button.canceluc')}
                                    </SmallButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                        <Grid item>
                            {state.buzy && <ReactLoading type={"bubbles"} color={theme.palette.primary.main} height={'50px'} width={'50px'} />}
                        </Grid>
                        <Grid item>
                            <div style={{ paddingTop: '40px', textAlign: 'center' }}>
                                {state.OrderOpen && !state.CancelText && <div>{t('selfcare:info.orderprocessed')}</div>}
                                {state.CancelText &&
                                    <Alert severity="info" style={{ textAlign: 'left', marginBottom: '10px' }}>
                                        <AlertTitle>{t('selfcare:info.orderprocessed')}</AlertTitle>
                                        <span dangerouslySetInnerHTML={{ __html: state.CancelText }} /><br /><a href="#" onClick={(event) => { setState({ CancelChangeDialogOpen: true }); event.preventDefault(); }}>{state.IsSweden ? 'Ångra byte' : 'Angre endring'}</a>
                                    </Alert>}
                            </div>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </>
    );
}
